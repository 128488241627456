import React from 'react'
import styled from 'styled-components'
import mediaQuery from '../styles/mediaQuery';
import BackgroundImage from 'gatsby-background-image-es5';
import { useStaticQuery, graphql } from 'gatsby';

export type Props = {
  logo?: string,
  navitems?: Array<{
    title?: string,
    url?: boolean | string,
    children?: boolean | Array<{
      title?: string,
      url?: string,
    }>
  }>,
  bannerTitle?: string,
  bannerTitleSpan?: string,
  isError?: boolean
}

const Container = styled.div`
    width: 100%
`
const StyledTop = styled.div`
  width: 100%;
  height: 4.625rem;
  background: #012F57 0% 0% no-repeat padding-box;
  opacity: 1;
  justify-content:center;
  display:flex;
  position: relative;
  @media screen and (max-width: 870px) {
    height: 4rem;
  }
`
const StyledBottom = styled.div<ErrorPageProps>`
  width: 100%;
  height: ${props => props.isError ? '22.8125rem' : '19.8125rem'};
  position: relative;
  @media screen and (max-width: 870px) {
    height: ${props => props.isError ? '17.625rem' : '8.625rem'};
  }
`

const GatsbyImageBg = styled(BackgroundImage as any)<ErrorPageProps>`
  width: 100%;
  height: ${props => props.isError ? '22.8125rem' : '19.8125rem'};
  position: absolute;
  left: 0;
  top: -1px;

  @media screen and (max-width: 870px) {
    height: ${props => props.isError ? '17.625rem' : '8.625rem'};
  }
`

const StyledMark = styled.div<ErrorPageProps>`
  position: absolute;
  width: 100%;
  height: ${props => props.isError ? '22.8125rem' : '19.8125rem'};
  top: -1px;
  left: 0;
  opacity: 0.7;
  overflow: hidden;
  transform: rotate(180deg);
  background: transparent linear-gradient( #191B1C 0%, #191B1CB4 63%, #191B1C00 100%) 0% 0% no-repeat padding-box;
  @media screen and (max-width: 870px) {
    height: ${props => props.isError ? '17.625rem' : '8.625rem'};
    opacity: 0.3;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    &::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0,0,0,0.5);
    }
  }
`

interface ErrorPageProps {
  isError?: boolean
}

const StyledTitle = styled.h1<ErrorPageProps>`
  text-align: center;
  font-weight: 900;
  font-size: ${props => props.isError ? '2.375rem' : '3rem'};
  line-height: 3.5625rem;
  font-family: museo-sans, sans-serif;
  letter-spacing: ${props => props.isError ? '-0.76px' : '-0.96px'};
  color: #FFFFFF;
  opacity: 1;
  margin: ${props => props.isError ? '9.3125rem 0 0 0' : '10.1875rem 0 0 0'};
  @media screen and (max-width: 870px) {
    font-size: ${props => props.isError ? '1.5rem' : '1.75rem'};
    line-height: 2.1875rem;
    letter-spacing: 0px;
    margin: 0;
    margin-top: ${props => props.isError ? '8.25rem' : '3.6875rem'};
  }
`

const StyledError = styled.p<ErrorPageProps>`
  display: ${props => props.isError ? 'block' : 'none'};
  font-family: museo-sans, sans-serif;
  font-size: 12.5rem;
  line-height: 3.5625rem;
  letter-spacing: -4px;
  color: #fff;
  font-weight: 900;
  margin: 0;
  position: absolute;
  bottom: 2.375rem;
  @media screen and (max-width: 870px) {
    font-size: 8.875rem;
    letter-spacing: -2.84px;
    bottom: 1.125rem;
  }
`
const ContainerTitle = styled.div<ErrorPageProps>`
  width: 100%;
  height: ${props => props.isError ? '22.8125rem' : '19.8125rem'};
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  display: flex;
  @media screen and (max-width: 870px) {
    height: ${props => props.isError ? '17.625rem' : '8.625rem'};

  }
`
const SpanStyled = styled.span`
  font-size: 3rem;
  @media screen and (max-width: 870px) {
    display: none;
  }
`
const Logo = styled.img`
  margin-left: 1.25rem;
  margin-top: 0.9375rem;
  width: 5.8125rem;
  height: 2.25rem;
  position: absolute;
  z-index: 1;
  ${mediaQuery('xl')`
    margin-left: 2.375rem;
    width: 6.81125rem;
    height: 2.605rem;
    margin-bottom: 1.065625rem;
  `}

  @media screen and (max-width: 870px) {
    margin-top: 0.8125rem;
  }

  @media all and (min-width: 60em) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 8.51rem;
    height: 3.25rem;
  }
`

export const Header: React.FC<Props> = ({
  logo, bannerTitle, bannerTitleSpan, isError
}) => {
  const { file } = useStaticQuery(graphql`
      {
          file(relativePath: {eq: "bg-header.jpg"}) {
              childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  return (
    <Container>
      <a href="https://www.sda.au/" target="_blank">
        <Logo
          src={logo}
          alt="logo"
          />
      </a>
      <StyledBottom isError={isError}>
        <GatsbyImageBg
          fluid={file.childImageSharp.fluid}
          isError={isError}
        />
        <StyledMark isError={isError} />
        <ContainerTitle isError={isError}>
          <StyledTitle isError={isError}>
            {bannerTitle}
            <SpanStyled>
              {' '}
              {bannerTitleSpan}
            </SpanStyled>
          </StyledTitle>
          <StyledError isError={isError}>404</StyledError>
        </ContainerTitle>

      </StyledBottom>
    </Container>
  )
}

export default Header
